import React from 'react';

const ServicesContentMigration = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2955 42.648H9C8.60717 42.6512 8.21716 42.5768 7.85332 42.4286C7.48948 42.2805 7.15897 42.0616 6.88008 41.785C6.60118 41.5083 6.37957 41.1793 6.22852 40.8167C6.07746 40.4541 5.99999 40.0649 6 39.6721V9C6 8.20435 6.31571 7.44152 6.87832 6.87891C7.44093 6.3163 8.20435 6 9 6H25.2955C26.0912 6 26.8546 6.3163 27.4172 6.87891C27.9798 7.44152 28.2955 8.20435 28.2955 9V39.6721C28.2955 40.0649 28.218 40.4541 28.067 40.8167C27.9159 41.1793 27.6943 41.5083 27.4154 41.785C27.1365 42.0616 26.806 42.2805 26.4422 42.4286C26.0783 42.5768 25.6883 42.6512 25.2955 42.648ZM9 7.2C8.52261 7.2 8.06432 7.38978 7.72676 7.72734C7.38919 8.06491 7.2 8.52261 7.2 9V39.6721C7.19998 39.9073 7.24636 40.1403 7.33711 40.3573C7.42786 40.5744 7.56105 40.7713 7.72852 40.9365C7.89598 41.1018 8.09444 41.2322 8.3127 41.32C8.53095 41.4078 8.76476 41.4512 9 41.448H25.2955C25.7688 41.4481 26.2231 41.2619 26.56 40.9295C26.8968 40.5971 27.0892 40.1453 27.0955 39.6721V9C27.0893 8.52454 26.8979 8.07031 26.5617 7.73408C26.2255 7.39785 25.771 7.20622 25.2955 7.2H9Z"
      fill="#475467"
    />
    <path
      d="M27.6955 10.4344H6.6C6.44087 10.4344 6.2883 10.3711 6.17578 10.2586C6.06326 10.1461 6 9.9935 6 9.83437C6 9.67525 6.06326 9.52239 6.17578 9.40986C6.2883 9.29734 6.44087 9.23438 6.6 9.23438H27.6955C27.8546 9.23438 28.0078 9.29734 28.1203 9.40986C28.2328 9.52239 28.2955 9.67525 28.2955 9.83437C28.2955 9.9935 28.2328 10.1461 28.1203 10.2586C28.0078 10.3711 27.8546 10.4344 27.6955 10.4344Z"
      fill="#475467"
    />
    <path
      d="M27.6955 38.0164H6.6C6.44087 38.0164 6.2883 37.9531 6.17578 37.8406C6.06326 37.7281 6 37.5755 6 37.4164C6 37.2573 6.06326 37.1047 6.17578 36.9922C6.2883 36.8797 6.44087 36.8164 6.6 36.8164H27.6955C27.8546 36.8164 28.0078 36.8797 28.1203 36.9922C28.2328 37.1047 28.2955 37.2573 28.2955 37.4164C28.2955 37.5755 28.2328 37.7281 28.1203 37.8406C28.0078 37.9531 27.8546 38.0164 27.6955 38.0164Z"
      fill="#475467"
    />
    <path
      d="M18.2396 40.368H16.0558C15.8967 40.368 15.7441 40.3047 15.6316 40.1922C15.5191 40.0797 15.4558 39.9271 15.4558 39.768C15.4558 39.6088 15.5191 39.4563 15.6316 39.3438C15.7441 39.2312 15.8967 39.168 16.0558 39.168H18.2396C18.3987 39.168 18.5513 39.2312 18.6638 39.3438C18.7763 39.4563 18.8396 39.6088 18.8396 39.768C18.8396 39.9271 18.7763 40.0797 18.6638 40.1922C18.5513 40.3047 18.3987 40.368 18.2396 40.368Z"
      fill="#475467"
    />
    <path
      d="M10.8 33.211C10.3364 33.1872 9.8896 33.0295 9.51386 32.7569C9.13811 32.4844 8.84957 32.1085 8.683 31.6753C8.51643 31.242 8.47879 30.7697 8.57518 30.3156C8.67158 29.8615 8.89734 29.4453 9.22557 29.1171C9.5538 28.7888 9.97034 28.5628 10.4244 28.4664C10.8785 28.37 11.3511 28.4073 11.7844 28.5739C12.2176 28.7405 12.5929 29.029 12.8654 29.4048C13.138 29.7805 13.2957 30.2273 13.3195 30.6909C13.3368 31.0264 13.2836 31.3617 13.1631 31.6753C13.0425 31.9888 12.8574 32.2736 12.6199 32.5111C12.3824 32.7486 12.0979 32.9337 11.7844 33.0543C11.4708 33.1748 11.1355 33.2283 10.8 33.211ZM10.8 29.3711C10.5389 29.3711 10.2835 29.4484 10.0664 29.5934C9.84932 29.7385 9.67997 29.9446 9.58007 30.1858C9.48016 30.427 9.45413 30.6924 9.50507 30.9484C9.556 31.2045 9.68198 31.4397 9.86659 31.6243C10.0512 31.8089 10.2861 31.9346 10.5422 31.9855C10.7982 32.0365 11.0639 32.0104 11.3051 31.9105C11.5463 31.8106 11.7524 31.6413 11.8974 31.4242C12.0425 31.2071 12.1195 30.952 12.1195 30.6909C12.1195 30.5176 12.0857 30.346 12.0193 30.1858C11.953 30.0257 11.856 29.8801 11.7334 29.7575C11.6108 29.6349 11.4652 29.5376 11.3051 29.4713C11.1449 29.4049 10.9733 29.3711 10.8 29.3711Z"
      fill="#475467"
    />
    <path
      d="M20.4956 19.0275C20.0267 19.004 19.5751 18.8436 19.1965 18.5661C18.818 18.2885 18.5291 17.9061 18.3657 17.466C18.2023 17.0259 18.1715 16.5473 18.2772 16.0899C18.3829 15.6325 18.6205 15.2163 18.9604 14.8925C19.3003 14.5688 19.7277 14.3518 20.1897 14.2685C20.6517 14.1852 21.128 14.2394 21.5596 14.4241C21.9912 14.6087 22.3596 14.9157 22.6184 15.3074C22.8772 15.699 23.0151 16.1581 23.0157 16.6275C23.0161 16.953 22.9504 17.2754 22.8223 17.5747C22.6943 17.8739 22.5067 18.1438 22.271 18.3683C22.0352 18.5928 21.7564 18.7671 21.4512 18.8804C21.1461 18.9937 20.8207 19.0438 20.4956 19.0275ZM20.4956 15.1876C20.3222 15.1876 20.1506 15.2217 19.9905 15.288C19.8303 15.3544 19.6847 15.4514 19.5622 15.574C19.4396 15.6965 19.3426 15.8421 19.2762 16.0023C19.2099 16.1624 19.176 16.334 19.176 16.5074C19.176 16.8575 19.3146 17.1932 19.5622 17.4408C19.8097 17.6883 20.1455 17.8275 20.4956 17.8275C20.8456 17.8275 21.1814 17.6883 21.429 17.4408C21.6765 17.1932 21.8157 16.8575 21.8157 16.5074C21.8094 16.1615 21.6676 15.8319 21.4208 15.5895C21.1739 15.3471 20.8415 15.2115 20.4956 15.2116V15.1876Z"
      fill="#475467"
    />
    <path
      d="M32.7124 29.3674C32.2435 29.3439 31.7919 29.1833 31.4133 28.9057C31.0348 28.6281 30.7459 28.2457 30.5825 27.8056C30.4191 27.3655 30.3883 26.8872 30.494 26.4298C30.5997 25.9724 30.8373 25.5562 31.1772 25.2324C31.5171 24.9087 31.9445 24.6917 32.4065 24.6084C32.8685 24.5251 33.3448 24.579 33.7764 24.7637C34.208 24.9483 34.5758 25.2556 34.8346 25.6473C35.0934 26.0389 35.2319 26.498 35.2325 26.9674C35.2329 27.2929 35.1672 27.615 35.0391 27.9143C34.9111 28.2136 34.7235 28.4837 34.4878 28.7082C34.252 28.9327 33.9732 29.107 33.668 29.2203C33.3628 29.3336 33.0375 29.3837 32.7124 29.3674ZM32.7124 25.5272C32.3623 25.5272 32.0265 25.6663 31.779 25.9139C31.5314 26.1614 31.3922 26.4972 31.3922 26.8473C31.3922 27.1974 31.5314 27.5331 31.779 27.7807C32.0265 28.0282 32.3623 28.1674 32.7124 28.1674C33.0624 28.1674 33.3982 28.0282 33.6458 27.7807C33.8933 27.5331 34.0325 27.1974 34.0325 26.8473C34.0325 26.4972 33.8933 26.1614 33.6458 25.9139C33.3982 25.6663 33.0624 25.5272 32.7124 25.5272Z"
      fill="#475467"
    />
    <path
      d="M30.8883 26.8267C30.7253 26.8193 30.5712 26.7509 30.4565 26.6348L21.8643 17.5627C21.7544 17.4505 21.6926 17.2996 21.6926 17.1426C21.6926 16.9855 21.7544 16.8349 21.8643 16.7228C21.9203 16.6647 21.9875 16.6184 22.0618 16.5868C22.136 16.5553 22.2155 16.5391 22.2961 16.5391C22.3768 16.5391 22.4569 16.5553 22.5311 16.5868C22.6053 16.6184 22.6726 16.6647 22.7286 16.7228L31.3202 25.7945C31.4301 25.9067 31.4918 26.0576 31.4918 26.2146C31.4918 26.3717 31.4301 26.5226 31.3202 26.6348C31.2658 26.6955 31.1995 26.7441 31.125 26.7771C31.0506 26.8102 30.9698 26.827 30.8883 26.8267Z"
      fill="#475467"
    />
    <path
      d="M12.3598 30.1925C12.265 30.2141 12.1663 30.2141 12.0715 30.1925C11.9353 30.1123 11.8365 29.9817 11.7961 29.8289C11.7557 29.6761 11.7771 29.5136 11.8559 29.3765L18.4078 17.3766C18.4455 17.3071 18.4964 17.2455 18.5578 17.1958C18.6193 17.1461 18.69 17.1091 18.7658 17.0868C18.8417 17.0645 18.921 17.0575 18.9996 17.066C19.0782 17.0745 19.1547 17.0983 19.224 17.1363C19.3598 17.2168 19.4596 17.3458 19.5041 17.4973C19.5486 17.6487 19.5343 17.8114 19.4637 17.9525L12.8877 29.9525C12.8272 30.0339 12.7468 30.0983 12.6545 30.1403C12.5622 30.1823 12.4609 30.2004 12.3598 30.1925Z"
      fill="#475467"
    />
    <path
      d="M34.1519 26.2314C34.011 26.23 33.8746 26.1789 33.7675 26.0873C33.6576 25.9751 33.5964 25.8245 33.5964 25.6675C33.5964 25.5104 33.6576 25.3595 33.7675 25.2474L40.9675 16.1756C41.0797 16.0656 41.2306 16.0039 41.3876 16.0039C41.5447 16.0039 41.6956 16.0656 41.8078 16.1756C41.8652 16.2295 41.9107 16.2944 41.9419 16.3666C41.9732 16.4388 41.9894 16.5167 41.9894 16.5954C41.9894 16.6741 41.9732 16.752 41.9419 16.8242C41.9107 16.8965 41.8652 16.9617 41.8078 17.0155L34.6078 26.1113C34.5438 26.1611 34.4705 26.1976 34.3921 26.2183C34.3138 26.2389 34.2321 26.2433 34.1519 26.2314Z"
      fill="#475467"
    />
    <path
      d="M41.9278 20.5916C41.7867 20.5928 41.6502 20.5419 41.544 20.449C41.4378 20.3561 41.3694 20.2274 41.3518 20.0874L40.9194 17.2316L37.8239 17.7595C37.745 17.7732 37.664 17.7709 37.586 17.7528C37.508 17.7347 37.4341 17.7013 37.3692 17.6543C37.3043 17.6074 37.2496 17.5479 37.2081 17.4794C37.1665 17.411 37.1394 17.3348 37.1278 17.2556C37.1058 17.1005 37.1426 16.9427 37.2315 16.8138C37.3204 16.6849 37.4549 16.5941 37.6077 16.5595L41.3278 15.9355C41.4758 15.8861 41.6357 15.8861 41.7836 15.9355C41.8514 15.9814 41.9079 16.0419 41.9495 16.1124C41.991 16.183 42.0166 16.262 42.0239 16.3436L42.5278 19.8235C42.5484 19.9792 42.5072 20.137 42.4129 20.2626C42.3187 20.3883 42.1791 20.4718 42.0239 20.4956L41.9278 20.5916Z"
      fill="#475467"
    />
  </svg>
);

export default ServicesContentMigration;
